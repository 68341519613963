<template>
  <div class="page-recipes">
    <!-- SLIDE -->
    <div class="page-recipes__slide">
      <img
        v-if="$i18n.locale === 'ru'"
        src="@/assets/img/recipes/slide.png"
        alt="Slide image"
        class="page-recipes__slide-desktop"
      />
      <img
        v-else
        src="@/assets/img/recipes/slide-uz.png"
        alt="Slide image"
        class="page-recipes__slide-desktop"
      />
      <img
        v-if="$i18n.locale === 'ru'"
        src="@/assets/img/recipes/slide-mobile.png"
        alt="Slide image"
        class="page-recipes__slide-mobile"
      />
      <img
        v-else
        src="@/assets/img/recipes/slide-mobile-uz.png"
        alt="Slide image"
        class="page-recipes__slide-mobile"
      />
    </div>

    <recipe-recommendation-list
      :title="recipeTitle"
      :isPaginate="isPaginate"
      sort="asc"
      type="anyTaste"
    />

    <!-- Any taste block -->
    <articles-block />

    <recipe-recommendation-list
      :title="dishesTitle"
      :isPaginate="isPaginate"
      sort="asc"
      type="lunchAndDinner"
    />

    <discount-list />
  </div>
</template>

<script>
export default {
  name: "CookingPage",
  components: {
    DiscountList: () => import("@/components/discount/DiscountList.vue"),
    RecipeRecommendationList: () =>
      import("@/components/recipe/RecipeRecommendationList.vue"),
    ArticlesBlock: () => import("@/components/articles/ArticlesBlock.vue"),
  },
  data() {
    return {
      recipeTitle: "recipesTaste",
      isPaginate: true,
      interesting: null,
      dishesTitle: "greatFood",
    };
  },
};
</script>

<style scoped></style>
